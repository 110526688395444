<template>
	<div>
		<Breadcrumb></Breadcrumb>
		<el-row class="" :gutter="20" type="flex">
		      <el-col :span="24">
		        <el-card class="mt-2">
		          <h4 class="fs_18 font-weight-semibold m-0 text-000 mb-3">项目基本信息</h4>
		          <div class="d-flex align-items-center flex-wrap">
					  <div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							项目编号：
						</div>
						<div class="flex-1">
							{{ info.code || '' }}
						</div>
					  </div>
                      <div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							申请人：
						</div>
						<div class="flex-1">
							 {{ info.username || '' }}
						</div>
                      </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							年级：
						</div>
						<div class="flex-1">
							 {{ info.nianji_txt || '' }}
						</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							学科：
						</div>
						<div class="flex-1">
							 {{ info.xueke_txt || '' }}
						</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							申请时间：
						</div>
						<div class="flex-1">
							{{ info.ctime || '' }}
						</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							荣誉名称：
						</div>
						<div class="flex-1">
							{{ info.title || '' }}
						</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							申请类型：
						</div>
						<div class="flex-1 ">
							<div class="flex-1">
								{{ info.type_name || '' }}
							</div>
						</div>
					  </div>
					  <div class="w-100 flex align-center mt-3">
						<div class="flex align-center opacity-60" style="width: 100px;">
							上传材料：
						</div>
						<div class="flex-1 flex flex-column">
							<div class="d-flex align-items-center justify-content-between mb-2" v-for="(file, index) in info.files" :key="index">
								<div class="cursor-pointer view" v-if="file.ext == 'jpg' || file.ext == 'png' || file.ext == 'jpeg'" @click="viewImage(file,'path')">
								  <img :src="file.path" style="width: 100px;height: 100px;">
								</div>
								<div class="cursor-pointer view" v-else @click="downloadview(file)">
								  <i class="el-icon-document mr-2"></i>
								  <span>{{ file.name }}</span>
								</div>
								<div class="opacity-80 ml-5 mt-1">
								  <i class="el-icon-download cursor-pointer view" @click="downloadview(file)"></i>
								</div>
							</div>
						</div>
					  </div>
		          </div>
				  <div class="w-100 flex-all mt-2" v-if="info.status == 2 && role_id != 30">
				  	 <el-button type="primary" size="medium" @click="operate" style="width: 300px;">审核</el-button>		
				  </div>
		        </el-card>
				<el-card class="mt-2">
				  <h4 class="fs_18 font-weight-semibold m-0 text-000 mb-3">审核信息</h4>
				  <div class="d-flex align-items-center flex-wrap">
					<div class="w-100 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							审核结果：
						</div>
						<div class="flex-1">
							<el-tag v-if="info.status === 1" size="mini" type="success">已通过</el-tag>
							<el-tag v-if="info.status === 2" size="mini" type="warning">待审核</el-tag>
							<el-tag v-if="info.status === 3" size="mini" type="danger">驳回</el-tag>
						</div>
					</div>
					<div class="w-100 flex align-center mb-2" v-if="info.manage && info.status === 1">
						<div class="flex align-center opacity-60" style="width: 100px;">
							审核时间：
						</div>
						<div class="flex-1">
							{{ info.utime || '' }}
						</div>
					</div>
					<div class="w-100 flex align-center mb-2" v-if="info.manage && info.status === 1">
						<div class="flex align-center opacity-60" style="width: 100px;">
							审核人：
						</div>
						<div class="flex-1">
							{{ info.manage.username || '' }}
						</div>
					</div>
					<div class="w-100 flex align-center mb-2" v-if="info.score > 0 && info.status === 1">
						<div class="flex align-center opacity-60" style="width: 100px;">
							分数：
						</div>
						<div class="flex-1">
							{{ info.score || '' }}
						</div>
					</div>
					<div class="w-100 flex align-center mb-2" v-if="info.status === 3">
						<div class="flex align-center opacity-60" style="width: 100px;">
							反馈内容：
						</div>
						<div class="flex-1">
							<div class="mt-2" v-html="info.remarks"></div>
						</div>
					</div>
				  </div>
				</el-card>
		      </el-col>
		</el-row>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="650px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small">
			<el-form-item  label="审核状态" prop="status">
				<el-radio-group v-model="form.status">
				   <el-radio :label="1">通过</el-radio>
				   <el-radio :label="3">驳回</el-radio>
				 </el-radio-group>
			</el-form-item>
			<el-form-item label="分数" prop="score">
				<el-input type="number" v-model="form.score" placeholder="请输入得分"></el-input>
				<small class="text-danger font-s" v-if="form.id">最大分数5分</small>
			</el-form-item>
			<el-form-item label="反馈意见" v-if="form.status == 3">
				<el-input type="textarea" v-model="form.remarks" placeholder="请输入反馈意见" :rows="3" maxlength="500" show-word-limit></el-input>
			</el-form-item>
		  </el-form>
		  <div class="text-danger mt-2 px-5 bx" style="line-height: 25px;">
		    备注：<br/>
		    按“国家级、省级、市级（市政府盖章）、市教育局级（含市人社局）、校级”分别记“5、4、3、2、1”分<br/>
		  </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
			Breadcrumb
		},
		data() {
			const checkMax = (rule, value, callback) => {
			  if (value > 5) {
				callback(new Error('最大值是5'))
			  } else {
				callback()
			  }
			}
			return {
				preUrl: 'user_hjmc',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				id:'',
				info:{},
				form:{
					id:'',
					status:'',
					remarks:'',
					score:'',
				},
				rules:{
					status: [
					  { required: true, message: '请选择审核状态', trigger: 'blur' }
					],
					score: [
					  // { required: true, message: '请填写审核分数', trigger: ['blur', 'change'] },
					  { validator: checkMax, trigger: ['blur', 'change'] },
					],
				},
				role_id:this.VueCookies.get('role_id'),
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
				this.getDetail()
			}
		},
		methods:{
			getDetail() {
			  this.axios.get(`/manage/${this.preUrl}/detail`, {
			    params: {
					id:this.id
				}
			  }).then(res => {
			    this.info = res.data
				this.form.id = res.data.id
			  })
			},
			reset() {
			  this.form = {
			    status:'',
			    remarks:'',
				score:'',
			  }
			  this.resetForm('form')
			},
			operate(){
				this.open = true
				this.title = '审核'
			},
			downloadview(file){
				this.axios.get('/manage/'+this.preUrl + '/download',{
					responseType: 'arraybuffer',
					params:{
						id:file.id
					}
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res]);
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = file.name;
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			handleSubmit(){
				this.$refs.form.validate(valid => {
				  if (valid) {
					  if(this.form.status == 1){
						  if(this.form.score == ''){
							  this.$message.error('请填写分数')
							  return false
						  }
					  }
					  this.axios.post('/manage/'+this.preUrl + '/operate', this.form).then(res => {
					    if (res.status) {
					      this.$message.success('审核成功')
					      this.open = false
					      this.getDetail()
					    } else {
					      this.$message.error(res.msg)
					    }
					  })
				  }
				})
			},
		}
	}
</script>

<style>
</style>